export const exportToTop = () =>
  setTimeout(() => window.scroll({ top: 0, left: 0, behavior: 'smooth' }), 1);

export const sortBy = (arr, k) =>
  arr.concat().sort((a, b) => (a[k] > b[k] ? 1 : a[k] < b[k] ? -1 : 0));

export const uObject = {
  isEqual: (...objects) =>
    objects.every((obj) => JSON.stringify(obj) === JSON.stringify(objects[0])),
  sort: (obj) =>
    Object.keys(obj)
      .sort()
      .reduce((p, c) => ((p[c] = obj[c]), p), {}),
};

export const capitalize = (str) =>
  `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

export const getDateFormatted = (date) => {
  const formatDate =
    date.length === 10 ? new Date(...[date.split('-')]) : new Date(date);

  return formatDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  });
};

export const getTimeFormatted = (date) =>
  new Date(date).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  });

export const getPhoneFormatted = (phone) => {
  const cleaned = ('' + phone).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

export const toKebabCase = (string) =>
  string
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase();
